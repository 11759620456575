import { useGetOrganizations } from 'hooks/queries/organizations/organizations';
import { useTranslation } from 'react-i18next';

import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import { useAuth0 } from '@auth0/auth0-react';

import { OrganizationInfo } from 'components/organization-info/organization-info';
import { PageLoader } from 'components/page-loader/page-loader';

import { UserModel } from 'models/user.model';

export const UserOrganizationsPage = () => {
  const auth = useAuth0<UserModel>();
  const { user: user_metadata } = auth;
  const { t } = useTranslation();
  const { data, isLoading, isError } = useGetOrganizations();

  const orgsFiltered = data?.filter((org) =>
    user_metadata?.app_metadata.orgId?.includes(org._id),
  );

  if (isError) {
    return <Box sx={{ py: 4, px: 8 }}>{t('load_organizations_failure')}</Box>;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        py: 4,
        px: 8,
      }}
    >
      {isLoading ? (
        <PageLoader />
      ) : (
        <>
          {!orgsFiltered?.length && (
            <Box>
              <Typography>{t('users.no_organizations_assigned')}</Typography>
            </Box>
          )}
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="flexStart"
            spacing={2}
            sx={{ width: '400px', maxWidth: '80%', margin: '0 auto' }}
          >
            {orgsFiltered?.length &&
              orgsFiltered.map((org) => (
                <OrganizationInfo key={org._id} organization={org} withLink />
              ))}
          </Grid>
        </>
      )}
    </Box>
  );
};
