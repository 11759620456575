import { yupResolver } from '@hookform/resolvers/yup';
import {
  useCreateOrganization,
  useUpdateOrganization,
} from 'hooks/mutations/organizations/organizations';
import { FC } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Routes } from 'routes/constants';

import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';

import { useAuth0 } from '@auth0/auth0-react';

import { UserModel } from 'models/user.model';

import { validationSchema } from './constants';
import {
  OrganizationInfoFormValues,
  OrganizationInfoModifyProps,
} from './types';

export const OrganizationInfoModify: FC<OrganizationInfoModifyProps> = ({
  organization,
  onCancel,
  isEditMode = false,
}) => {
  const navigate = useNavigate();
  const auth = useAuth0<UserModel>();
  const { t } = useTranslation();
  const { mutateAsync: createOrganization } = useCreateOrganization(auth);
  const { mutateAsync: updateOrganization } = useUpdateOrganization(auth);

  const defaultValues: OrganizationInfoFormValues = {
    name: {
      en: organization?.name.en ?? '',
      ua: organization?.name.ua ?? '',
    },
    address: organization?.address ?? '',
    edrpouCode: organization?.edrpouCode.toString() ?? '',
    storageUrl: organization?.storageUrl ?? '',
    phones: organization?.phones
      ? [...organization!.phones.map((phone) => ({ value: phone }))]
      : [{ value: '' }],
    useMCA: organization?.useMCA ?? true,
    useFMECA: organization?.useFMECA ?? false,
  };
  const { control, handleSubmit } = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });

  const {
    fields: phones,
    append: addPhone,
    remove: removePhone,
  } = useFieldArray({
    name: 'phones',
    control,
  });

  const onSubmit = async (data: typeof defaultValues) => {
    const payloadData = {
      ...data,
      logoUrl: data.logoUrl ?? undefined,
      phones: data.phones.map((phone) => phone.value),
      useFMECA: data.useFMECA,
      useMCA: data.useMCA,
    };

    if (organization) {
      await updateOrganization({
        data: payloadData,
        organizationId: organization._id,
      });
    } else {
      await createOrganization({
        data: payloadData,
      });
    }

    navigate(`/${Routes.ADMIN}/organizations`);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography>{t('organization_modify.title')}</Typography>
        </Grid>

        <Grid item xs={12}>
          <Controller
            name="name.ua"
            control={control}
            defaultValue={defaultValues.name.ua}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                error={!!error}
                helperText={error?.message}
                label={t('organization_modify.name_ua')}
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="name.en"
            control={control}
            defaultValue={defaultValues.name.en}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                error={!!error}
                helperText={error?.message}
                label={t('organization_modify.name_en')}
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="address"
            control={control}
            defaultValue={defaultValues.address}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                error={!!error}
                helperText={error?.message}
                label={t('organization_modify.address')}
                fullWidth
              />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Controller
            name="edrpouCode"
            control={control}
            defaultValue={defaultValues.edrpouCode}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                error={!!error}
                helperText={error?.message}
                label={t('organization_modify.edrpou_code')}
                fullWidth
              />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Controller
            name="storageUrl"
            control={control}
            defaultValue={defaultValues.storageUrl}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                error={!!error}
                helperText={error?.message}
                label={t('organization_modify.link_to_attachments')}
                fullWidth
              />
            )}
          />
        </Grid>

        <Grid container item spacing={2}>
          <Grid item xs={12}>
            <Typography>{t('organization_modify.phones')}</Typography>
          </Grid>

          {phones.map((phone, index) => (
            <Grid
              key={phone.id}
              container
              item
              xs={12}
              columnGap={2}
              alignItems="center"
            >
              <Controller
                control={control}
                name={`phones.${index}.value`}
                defaultValue=""
                render={({ field, fieldState: { error } }) => (
                  <>
                    <Grid item xs={11}>
                      <TextField
                        {...field}
                        error={!!error}
                        helperText={error?.message}
                        label={t('organization_modify.phone')}
                        fullWidth
                      />
                    </Grid>

                    <Grid item xs>
                      <IconButton
                        disabled={phones.length === 1}
                        onClick={() => removePhone(index)}
                        aria-label="delete"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Grid>
                  </>
                )}
              />
            </Grid>
          ))}
        </Grid>

        <Grid item xs={12}>
          <Button
            startIcon={<AddIcon />}
            type="button"
            variant="contained"
            color="primary"
            onClick={() => addPhone({ value: '' })}
          >
            {t('organization_modify.add_phone')}
          </Button>
        </Grid>
        <hr />
        {isEditMode && (
          <>
            <Grid item xs={12}>
              <Typography>
                {t('organization_modify.assessment_workflow')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="useMCA"
                control={control}
                defaultValue={defaultValues.useMCA}
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        {...field}
                        checked={field.value}
                        onChange={(e) => field.onChange(e.target.checked)}
                      />
                    }
                    label={t('organization_modify.use_MCA')}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                name="useFMECA"
                control={control}
                defaultValue={defaultValues.useFMECA}
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        {...field}
                        checked={field.value}
                        onChange={(e) => field.onChange(e.target.checked)}
                      />
                    }
                    label={t('organization_modify.use_FMECA')}
                  />
                )}
              />
            </Grid>
          </>
        )}

        <Grid item xs={12}>
          <Stack direction="row" spacing={2}>
            <Button type="submit" variant="contained" color="primary">
              {t('submit')}
            </Button>

            {organization && (
              <Button
                type="button"
                variant="contained"
                color="error"
                onClick={onCancel}
              >
                {t('cancel')}
              </Button>
            )}
          </Stack>
        </Grid>
      </Grid>
    </form>
  );
};
