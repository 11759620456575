import { useGetOrganizationById } from 'hooks/queries/organizations/organizations';
import { FC } from 'react';
import { useParams } from 'react-router-dom';

import { Box } from '@mui/material';

import { OrganizationBlockList } from 'components/organization-block-list/organization-block-list';
import { OrganizationInfo } from 'components/organization-info/organization-info';
import { PageLoader } from 'components/page-loader/page-loader';

import { headerHeight } from 'constants/index';

export const OrganizationDetailsPage: FC = () => {
  const { organizationId } = useParams();
  const { data: organization, isLoading: isLoadingOrganization } =
    useGetOrganizationById(organizationId);

  if (isLoadingOrganization) {
    return <PageLoader />;
  }

  return (
    <Box
      sx={{
        py: 4,
        px: 8,
        minHeight: `calc(100vh - ${headerHeight + 1}px)`,
      }}
    >
      <Box sx={{ mb: 13 }}>
        <OrganizationInfo organization={organization} />
      </Box>

      <OrganizationBlockList
        useMCA={organization?.useMCA}
        useFMECA={organization?.useFMECA}
        processes={organization?.processes ?? []}
      />
    </Box>
  );
};
