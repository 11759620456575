export enum Routes {
  ADMIN = 'admin',
  ORGANIZATIONS = 'organizations',
  PROCESSES = 'processes',
  STEPS = 'steps',
  GENERAL_INFORMATION_SURVEY = 'general-information-survey',
  DETAILED_ASSESSMENT_SURVEY = 'detailed-assessment-survey',
  INVESTMENT_PLAN = 'investment-plan',
  USER_ORGANIZATIONS = 'user-organizations',
}

export enum InvestmentPlanRoutes {
  UTILITY_ASSESSMENT = 'utility-assessment',
  SHORTLIST_CRITERIA = 'shortlist-criteria',
  INVESTMENT_PROJECTS = 'investment-projects',
}

export enum InvestmentProjectRoutes {
  PROJECT_DETAILS = 'project-details',
  EVALUATION = 'evaluation',
  PROJECT_FACTSHEETS = 'project-factsheet',
  FEASIBILITY_IN_DETAILS = 'feasibility-in-details',
}

export enum UtilityAssessmentRoutes {
  INSTITUTIONAL_AND_EFFICIENCY_ASSESSMENT = 'institutional-and-efficiency-assessment',
  TECHNICAL_AND_EFFICIENCY_ASSESSMENT = 'technical-and-efficiency-assessment',
  COMMERCIAL_AND_FINANCIAL_ASSESSMENT = 'commercial-and-financial-assessment',
}

export enum TechnicalAndEfficiencyAssessmentRoutes {
  PRELIMINARY_QUESTIONS = 'preliminary-questions',
  GENERAL_DATA = 'general-data',
  OPERATION_OF_ARTESIAN_WELLS = 'operation-of-artesian-wells',
  WORK_OF_THE_WATER_SUPPLY_SERVICE = 'work-of-the-water-supply-service',
  OPERATION_OF_BOOSTING_PUMPING_STATIONS = 'operation-of-boosting-pumping-stations',
  OPERATION_OF_WASTEWATER_PUMPING_STATIONS = 'operation-of-wastewater-pumping-stations',
}

export enum DetailedAssessmentRoutes {
  DECOMPOSITION = 'decomposition',
  RISK_MATRIX = 'risk-matrix',
  POTENTIAL_FAILURES = 'failures-and-remedy',
  RECOMMENDED_PRIORITY = 'recommended-priority',
}
