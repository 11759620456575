import { Auth0ContextInterface } from '@auth0/auth0-react';

import { UserModel } from 'models/user.model';

import {
  axiosDelete,
  axiosGet,
  axiosPost,
  axiosUpdate,
} from './external-api.service';

export const usersService = {
  getUsers(auth: Auth0ContextInterface<UserModel>): Promise<UserModel[]> {
    return axiosGet<UserModel[]>(auth, '/v2/users').then((response) =>
      response.map(({ user_id, userId: _, ...rest }) => ({
        userId: user_id,
        ...rest,
      })),
    );
  },

  addUser(
    auth: Auth0ContextInterface<UserModel>,
    data: Partial<UserModel>,
  ): Promise<UserModel> {
    return axiosPost(auth, '/v2/users', data);
  },

  updateUserOrganizations(
    auth: Auth0ContextInterface<UserModel>,
    userId: string,
    data: Partial<UserModel>,
  ): Promise<UserModel> {
    return axiosUpdate<UserModel, typeof data>(
      auth,
      `/v2/users/${userId}`,
      data,
    ).then((response) => {
      return { ...response, userId: response.user_id };
    });
  },

  deleteUser(auth: Auth0ContextInterface<UserModel>, userId: string) {
    return axiosDelete(auth, `/v2/users/${userId}`);
  },
};
