import {
  useAddUser,
  useDeleteUser,
  useUpdateUser,
} from 'hooks/mutations/admin-users/admin-users';
import { useGetOrganizations } from 'hooks/queries/organizations/organizations';
import { useGetUsers } from 'hooks/queries/users/users';
import React, { useRef, useState } from 'react';
import { FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import AddBoxIcon from '@mui/icons-material/AddBox';
import ClearIcon from '@mui/icons-material/Clear';
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Grid';

import { useAuth0 } from '@auth0/auth0-react';

import { ConfirmationDialog } from 'components/confirmation-dialog/confirmation-dialog';
import { StyledLightTooltip } from 'components/investment-plan-layout/styles';

import { UserModel } from 'models/user.model';

import { useIsEnLanguage } from 'hooks/hooks/useIsEnLanguage';

import { AddUserModal } from './add-user-modal/add-user-modal';
import { AddUserModalHandle } from './add-user-modal/types';
import { StyledSectionsWrapper } from './styles';

export const AdminUsersPage = () => {
  const { t } = useTranslation();
  const auth = useAuth0<UserModel>();

  const modalRef = useRef<AddUserModalHandle | null>(null);

  const [isOpenConfirmationDialog, setIsOpenConfirmationDialog] =
    useState(false);
  const [
    isOpenConfirmationDialogUserExists,
    setIsOpenConfirmationDialogUserExists,
  ] = useState(false);
  const [
    isOpenConfirmDialogRemoveFromOrganization,
    setIsOpenConfirmDialogRemoveFromOrganization,
  ] = useState(false);
  const [email, setEmail] = useState<string | null>(null);
  const [user, setUser] = useState<UserModel | null>(null);
  const [orgId, setOrgId] = useState<string | null>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [organizationId, setOrganizationId] = useState<string | null>(null);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleResetForm = () => {
    if (modalRef.current) {
      modalRef.current?.resetForm();
    }
  };

  const { data: users } = useGetUsers();
  const { mutateAsync: deleteUser } = useDeleteUser();
  const { mutateAsync: updateUser } = useUpdateUser();
  const { mutateAsync: addUser } = useAddUser();

  const { data: allOrganizations } = useGetOrganizations();
  const { currentLanguage } = useIsEnLanguage();

  const onAddUserButtonClick = (_: EventTarget, orgId: string) => {
    setOrganizationId(orgId);
    handleOpen();
  };

  const onRemoveUserFromOrganizationButtonClick = (
    email: string,
    organizationId: string,
  ) => {
    const user = users?.find((user) => user.email === email);
    const organizations = user ? user.app_metadata.orgId ?? [] : [];
    const updatedOrganizations = organizations.filter(
      (id) => id !== organizationId,
    );

    if (updatedOrganizations.length === 0) {
      setIsOpenConfirmationDialog(true);
    } else {
      setIsOpenConfirmDialogRemoveFromOrganization(true);
    }
    setEmail(email);
    setOrgId(organizationId);
  };

  const deleteUserHandler = async () => {
    const user = users?.find((user) => user.email === email);

    if (user) {
      await deleteUser({ auth, userId: user.userId });
      setIsOpenConfirmationDialog(false);
      setEmail(null);
    }
  };

  const removeUserFromOrganizationHandler = async () => {
    const user = users?.find((user) => user.email === email);

    if (user) {
      const orgIds = Array.isArray(user?.app_metadata?.orgId)
        ? user.app_metadata.orgId
        : [];
      const updatedOrganizationIds = orgIds.filter((id) => id !== orgId);

      await updateUser({
        auth,
        newUser: { orgId: [...updatedOrganizationIds] },
        userId: user.userId,
        message: t(
          'users.confirmation.user_was_successfully_deleted_from_the_organization',
        ),
      });

      setUser(null);
      setEmail(null);
      setOrgId(null);
      setIsOpenConfirmDialogRemoveFromOrganization(false);
    }
  };

  const onSubmit = async (data: FieldValues) => {
    const user = users?.find((user) => user.email === data.email);

    if (user) {
      setIsOpenConfirmationDialogUserExists(true);
      setUser(user);
    } else {
      await addUser({ auth, newUser: { ...data, orgId: [organizationId] } });
    }
    handleResetForm();
    setOpen(false);
  };

  const userExistsHandler = async () => {
    if (!user) return;

    const orgIds = user?.app_metadata?.orgId || [];

    setIsOpenConfirmationDialogUserExists(false);

    await updateUser({
      auth,
      newUser: { orgId: [...orgIds, organizationId] },
      userId: user.userId,
      message: t(
        'users.confirmation.user_was_successfully_added_to_the_organization',
      ),
    });
  };

  const handleCancel = () => {
    handleResetForm();
    handleClose();
  };

  return (
    <>
      <Grid container justifyContent="center">
        <StyledSectionsWrapper>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography sx={{ fontWeight: 700 }}>
                      {t('organization_modify.name')}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography sx={{ fontWeight: 700 }}>
                      {t('users.users')}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {allOrganizations?.map((row) => (
                  <TableRow key={row._id}>
                    <TableCell>{row.name[currentLanguage]}</TableCell>
                    <TableCell>
                      {users
                        ?.filter((user) =>
                          user.app_metadata?.orgId?.includes(row._id),
                        )
                        ?.map((orgUser) => (
                          <Typography variant="h6" key={orgUser.email}>
                            {orgUser.email}
                            <StyledLightTooltip
                              title={t('users.remove_user')}
                              arrow
                              placement="top-start"
                            >
                              <IconButton
                                onClick={() =>
                                  onRemoveUserFromOrganizationButtonClick(
                                    orgUser.email as string,
                                    row._id,
                                  )
                                }
                                size="small"
                              >
                                <ClearIcon />
                              </IconButton>
                            </StyledLightTooltip>
                          </Typography>
                        )) || 'no users'}
                      <StyledLightTooltip
                        title={t('users.add_user_to_organization')}
                        arrow
                        placement="top-start"
                      >
                        <IconButton
                          onClick={(event) =>
                            onAddUserButtonClick(event.currentTarget, row._id)
                          }
                          size="small"
                        >
                          <AddBoxIcon />
                        </IconButton>
                      </StyledLightTooltip>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </StyledSectionsWrapper>
      </Grid>

      <AddUserModal
        handleClose={handleClose}
        handleCancel={handleCancel}
        onSubmit={onSubmit}
        open={open}
        ref={modalRef}
      ></AddUserModal>

      <ConfirmationDialog
        title={t('users.confirmation.delete_user_from_organization')}
        open={isOpenConfirmDialogRemoveFromOrganization}
        onClose={() => setIsOpenConfirmDialogRemoveFromOrganization(false)}
        onSubmit={removeUserFromOrganizationHandler}
      />
      <ConfirmationDialog
        title={t('users.confirmation.delete_user_from_system')}
        open={isOpenConfirmationDialog}
        onClose={() => setIsOpenConfirmationDialog(false)}
        onSubmit={deleteUserHandler}
      />
      <ConfirmationDialog
        title={t(
          'users.confirmation.user_exists_add_to_organization_with_previous_password',
        )}
        open={isOpenConfirmationDialogUserExists}
        onClose={() => setIsOpenConfirmationDialogUserExists(false)}
        onSubmit={userExistsHandler}
        args={{
          isAdd: true,
        }}
      />
    </>
  );
};
