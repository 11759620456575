import { Grid } from '@mui/material';

import { InvestmentPlanSection } from 'components/investment-plan-section/investment-plan-section';
import { ProcessItem } from 'components/processes/process-item/process-item';

import { OrganizationBlockListProps } from './types';

export const OrganizationBlockList = ({
  useMCA,
  useFMECA,
  processes,
}: OrganizationBlockListProps) => {
  return (
    <Grid container spacing={3}>
      {useMCA && <InvestmentPlanSection />}

      {useFMECA &&
        processes &&
        processes.map((process) => (
          <Grid key={process._id} item xs={12}>
            <ProcessItem process={process} />
          </Grid>
        ))}
    </Grid>
  );
};
