import { Box, styled } from '@mui/material';

import { theme } from 'configs/theme';

export const StyledSectionsWrapper = styled(Box)({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  backgroundColor: theme.palette.background.default,
  border: '2px solid #000',
  boxShadow: theme.shadows[0],
  padding: theme.spacing(4),
});
