import React, { forwardRef, useImperativeHandle } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  Box,
  Button,
  FormControl,
  Input,
  InputLabel,
  Modal,
  Typography,
} from '@mui/material';

import { StyledSectionsWrapper } from './styles';
import { AddUserModalHandle } from './types';

type AddUserModalProps = {
  handleClose: () => void;
  handleCancel: () => void;
  onSubmit: (data: FieldValues) => Promise<void>;
  open: boolean;
};

export const AddUserModal = forwardRef<AddUserModalHandle, AddUserModalProps>(
  ({ handleClose, handleCancel, onSubmit, open }, ref) => {
    const {
      register,
      handleSubmit,
      formState: { errors },
      reset,
    } = useForm();

    useImperativeHandle(ref, () => ({
      resetForm: () => reset(),
    }));

    const { t } = useTranslation();

    return (
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <StyledSectionsWrapper>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {t('users.add_user')}
          </Typography>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormControl sx={{ mt: 2 }} fullWidth>
              <InputLabel htmlFor="email">{t('users.email')}</InputLabel>
              <Input
                id="email"
                type="email"
                aria-describedby="email"
                {...register('email', { required: 'Email is required' })}
              />
              {errors.email && (
                <p>{t('users.errors.email_field_is_required')}</p>
              )}
            </FormControl>

            <FormControl sx={{ mt: 2 }} fullWidth>
              <InputLabel htmlFor="password">{t('users.password')}</InputLabel>
              <Input
                id="password"
                type="password"
                aria-describedby="password"
                {...register('password', {
                  required: 'Password is required',
                  minLength: {
                    value: 8,
                    message: t('users.errors.password_min_length'),
                  },
                  pattern: {
                    value:
                      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                    message: t('users.errors.password_invalid'),
                  },
                })}
              />
              {errors?.password?.message && (
                <div>
                  <p>{t(errors.password.message as string)}</p>
                </div>
              )}
            </FormControl>

            <Box sx={{ mt: 5, display: 'flex', justifyContent: 'flex-end' }}>
              <Box sx={{ ml: 1 }}>
                <Button
                  type="button"
                  variant="contained"
                  color="error"
                  onClick={handleCancel}
                >
                  {t('cancel')}
                </Button>
              </Box>
              <Box sx={{ ml: 1 }}>
                <Button type="submit" variant="contained" color="info">
                  {t('users.add')}
                </Button>
              </Box>
            </Box>
          </form>
        </StyledSectionsWrapper>
      </Modal>
    );
  },
);

AddUserModal.displayName = 'AddUserModal';
