import { AxiosError } from 'axios';
import { Severity } from 'context/types';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Auth0ContextInterface } from '@auth0/auth0-react';

import { useMutation } from 'react-query';
import { queryKeys } from 'react-query/constants';
import { queryClient } from 'react-query/queryClient';

import { UserModel } from 'models/user.model';

import { usersService } from 'services/users.service';

import { AppContext } from 'context';

export const useAddUser = () => {
  const { t } = useTranslation();
  const {
    notification: { showNotification },
  } = useContext(AppContext);

  const { mutateAsync, isLoading } = useMutation(
    async ({ auth, newUser }: Partial<UserModel>) =>
      await usersService.addUser(auth, newUser),
    {
      mutationKey: queryKeys.getUsers,
      onSuccess: (newUser: UserModel) => {
        if (newUser) {
          showNotification({
            isShowingNotification: true,
            type: Severity.Success,
            message: 'User was successfully added to the organization',
          });
          queryClient.setQueryData(
            queryKeys.getUsers,
            (oldData: UserModel[] | undefined) => [...(oldData || []), newUser],
          );
          setTimeout(() => {
            queryClient.invalidateQueries(queryKeys.getUsers);
          }, 1000);
        }
      },
      onError: (error: AxiosError) => {
        const errorMessage =
          (error.response?.data as { message: string })?.message ||
          t('errors.unknown_error_occurred');

        showNotification({
          isShowingNotification: true,
          type: Severity.Error,
          message: errorMessage,
        });
      },
    },
  );

  return {
    mutateAsync,
    isLoading,
  };
};

export const useUpdateUser = () => {
  const { t } = useTranslation();
  const {
    notification: { showNotification },
  } = useContext(AppContext);
  let notificationMessage = '';

  const { mutateAsync, isLoading } = useMutation(
    async ({
      auth,
      newUser,
      userId,
      message,
    }: Partial<UserModel> & { message: string }) => {
      notificationMessage = message;
      return await usersService.updateUserOrganizations(
        auth,
        userId as string,
        newUser,
      );
    },
    {
      mutationKey: queryKeys.getUsers,
      onSuccess: (updatedUser: UserModel) => {
        if (updatedUser) {
          showNotification({
            isShowingNotification: true,
            type: Severity.Success,
            message: notificationMessage,
          });
          queryClient.setQueryData(
            queryKeys.getUsers,
            (oldData: UserModel[] | undefined): UserModel[] => {
              const users = oldData ?? [];
              const userIndex = users.findIndex(
                (user) => user.userId === updatedUser.userId,
              );

              if (userIndex > -1) users[userIndex] = updatedUser;
              return users;
            },
          );
          setTimeout(async () => {
            await queryClient.invalidateQueries(queryKeys.getUsers);
          }, 1000);
        }
      },
      onError: (error: AxiosError) => {
        const errorMessage =
          (error.response?.data as { message: string })?.message ||
          t('errors.unknown_error_occurred');

        showNotification({
          isShowingNotification: true,
          type: Severity.Error,
          message: errorMessage,
        });
      },
    },
  );

  return {
    mutateAsync,
    isLoading,
  };
};

export const useDeleteUser = () => {
  const { t } = useTranslation();
  const {
    notification: { showNotification },
  } = useContext(AppContext);

  const { mutateAsync, isLoading } = useMutation(
    async ({ auth, userId }: Partial<UserModel>) =>
      await usersService.deleteUser(auth, userId as string),
    {
      mutationKey: queryKeys.getUsers,
      onSuccess: (deletedUser) => {
        if (deletedUser) {
          showNotification({
            isShowingNotification: true,
            type: Severity.Success,
            message: t('users.confirmation.user_was_successfully_deleted'),
          });
          queryClient.setQueryData(
            queryKeys.getUsers,
            (oldData: UserModel[] | undefined) =>
              oldData?.length
                ? oldData.filter(
                    (user: UserModel) =>
                      user.userId !== deletedUser.data.userId,
                  )
                : [],
          );
          setTimeout(async () => {
            await queryClient.invalidateQueries(queryKeys.getUsers);
          }, 1000);
        }
      },
      onError: (error: AxiosError) => {
        const errorMessage =
          (error.response?.data as { message: string })?.message ||
          t('errors.unknown_error_occurred');

        showNotification({
          isShowingNotification: true,
          type: Severity.Error,
          message: errorMessage,
        });
      },
    },
  );

  return {
    mutateAsync,
    isLoading,
  };
};
