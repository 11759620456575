import { Auth0ContextInterface } from '@auth0/auth0-react';

import { Language } from 'components/language-switcher/types';

import { UserModel } from 'models/user.model';

import { axiosGet } from './external-api.service';

export const reportService = {
  getReportUrl: (
    auth: Auth0ContextInterface<UserModel>,
    organizationId: string | undefined,
    processId: string | undefined,
    language: Language,
  ) => {
    if (!organizationId || !processId) return;

    return axiosGet<string>(
      auth,
      `/v1/export/${organizationId}/${processId}/${language.toLowerCase()}`,
    );
  },
};
