import { FC, PropsWithChildren } from 'react';
import { Navigate } from 'react-router-dom';
import { Routes } from 'routes/constants';

import { useAuth0 } from '@auth0/auth0-react';

import { PageLoader } from 'components/page-loader/page-loader';

import { UserModel } from 'models/user.model';

export const NotAdminRoleGuard: FC<PropsWithChildren> = ({ children }) => {
  const { isLoading, user } = useAuth0<UserModel>();

  if (isLoading) {
    return <PageLoader />;
  }

  if (!user?.app_metadata?.isAdmin) {
    const userOrgs = user?.app_metadata?.orgId as string[];

    return (
      <Navigate
        to={
          !userOrgs || userOrgs.length > 1 || !userOrgs.length
            ? Routes.USER_ORGANIZATIONS
            : `${Routes.ORGANIZATIONS}/${userOrgs[0]}`
        }
      />
    );
  }

  return <>{children}</>;
};
