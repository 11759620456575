import { useNavigate } from 'react-router-dom';
import { Routes } from 'routes/constants';

import Person from '@mui/icons-material/Person';
import { Box, Toolbar } from '@mui/material';

import { useAuth0 } from '@auth0/auth0-react';

import { AccountMenu } from 'components/account-menu/account-menu';

import { UserModel } from 'models/user.model';

import { ReactComponent as LogoIcon } from 'assets/cirnat-logo.svg';

import { headerHeight } from 'constants/index';

import { LanguageSwitcher } from '../language-switcher/language-switcher';
import { StyledAppBar, StyledGradientLine } from './styles';

export const Header = () => {
  const navigate = useNavigate();
  const { user } = useAuth0<UserModel>();

  return (
    <StyledAppBar position="sticky" color="info">
      <Toolbar sx={{ height: headerHeight, padding: '0 32px' }} disableGutters>
        <Box
          sx={{ cursor: 'pointer', minWidth: 192, flexGrow: 1 }}
          onClick={() => navigate('/')}
        >
          <LogoIcon />
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box sx={{ pr: 1 }}>
            <LanguageSwitcher />
          </Box>

          <AccountMenu />
        </Box>

        {user?.app_metadata?.isAdmin && (
          <Box
            sx={{ cursor: 'pointer', pr: 1 }}
            onClick={() => navigate(`${Routes.ADMIN}/users`)}
          >
            <Person />
          </Box>
        )}
      </Toolbar>

      <StyledGradientLine />
    </StyledAppBar>
  );
};
